/* eslint-disable @next/next/no-page-custom-font */
/* eslint-disable @next/next/no-css-tags */
import dynamic from "next/dynamic";
import { cloudinaryLoader } from "utils/cloudinaryUtils";
import { replaceStyleTag, stringMinify } from "utils/StringUtils";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "context/appContext";
import { CLEAR_CACHE } from "lib/graphql/queries/generic";
import { client } from "lib/apollo/apolloClient";
import useResize from "components/Hooks/useResize";
import useIntersectionObserver from "components/Hooks/useIntersectionObserver";
import { DeviceEnum } from "models/enums";

const Head = dynamic(() => import("next/head"));
const Image = dynamic(() => import("next/image"));
const Header = dynamic(() => import("components/Header/Header"));
const Footer = dynamic(() => import("components/Footer"));
const Loading = dynamic(() => import('components/Loading'));
const RenderScriptsContent = dynamic(() => import('components/RenderScripts/RenderScriptsContent'));

interface IProps {
    children: React.ReactNode,
    page?: string,
    settings: any,
    titlePage: string,
    friendlyUrl: string,
    keywords: string,
    metaDescription: string
}

const initialSettings = {
    theme: {},
    navBar: [],
    thirdParty: {},
    home: [],
    footer: {},
    companyInfo: {},
    globalScripts: {},
    generalInfo: {},
    page: null,
};


function Layout(props: IProps) {
    const { page, settings, titlePage, keywords, metaDescription } = props;
    const websiteId = Number(process.env.WEBSITE_ID) === 0 ? 1 : Number(process.env.WEBSITE_ID);

    const {
        theme,
        navBar,
        thirdParty,
        home = [],
        footer,
        companyInfo,
        globalScripts,
        generalInfo,
    } = settings || initialSettings;

    const { whichDevice } = useResize();
    const isMobile = whichDevice === DeviceEnum.MOBILE;
    const [openFilters, setOpenFilters] = useState(false);
    const { iconImage, scrollTop, homeSkin } = theme;

    const headerHeight = isMobile ? 55 : 65;
    const scrollTopType = scrollTop || "arrow-up";

    const globalStyles = replaceStyleTag(globalScripts?.styles);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const scrollTopElement = document.querySelector('.scroll-top') as HTMLElement | null;
        scrollTopElement?.classList.add("scroll-run");
        setTimeout(function () { scrollTopElement?.classList.remove('scroll-run'); }, 1000);
    };

    const openFilterInMobile = () => {
        const element = document.getElementsByClassName("product-listing")?.[0];
        if (element?.classList.contains("open-filters")) {
            element?.classList.remove("open-filters");
            setOpenFilters(false);
        }
        else {
            element.classList.add("open-filters");
            setOpenFilters(true);
        }
    };

    useEffect(() => {
        const scrollTopElement = document.querySelector('.scroll-top') as HTMLElement | null;
        window.addEventListener('scroll', () => {
            if (window.scrollY > 200) {
                scrollTopElement?.classList.add('show', 'scroll-down');
            } else {
                scrollTopElement?.classList.remove('show');
                setTimeout(function () { scrollTopElement?.classList.remove('scroll-down'); }, 300);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function assignPaddingTopToFirstElement() {
            const headerElement = document?.getElementById("header") as HTMLElement | null;
            const firstElement = document?.getElementById("main") as HTMLElement | null;
            let heightheader = headerHeight;
            if (headerElement) heightheader = headerElement?.offsetHeight > 0 ? headerElement.offsetHeight : heightheader;
            // TODO: this code should be removed when found why the main gets a height of 700px;
            if (heightheader > 500) setTimeout(() => window.dispatchEvent(new Event('resize')), 10);

            if (firstElement) {
                const additionalHeight = Number(['home', 'srp', 'vdp', 'admin'].includes(String(page)) ? 0 : 30);
                firstElement.style.paddingTop = `${heightheader + additionalHeight}px`;
            }
        }

        assignPaddingTopToFirstElement();
        window.addEventListener("resize", assignPaddingTopToFirstElement);

        return () => {
            window.removeEventListener("resize", assignPaddingTopToFirstElement);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titlePage]);

    const fontRuleCSS = `
    body {
      ${theme.fontCssRule}
  }`;

    useEffect(() => {
        const observer = client.subscribe({
            query: CLEAR_CACHE
        });

        const subscription = observer.subscribe((data: any) => {
            if (data?.data?.websiteChanged?.value) {
                fetch(`/api/clearcache?id=${data?.data?.websiteChanged?.value}`)
                    .then((response) => response.json());
            }
        });

        return () => subscription.unsubscribe();
    }, []);

    const footerSection = useRef(null);
    const showFooter = !['login', 'srp', 'admin'].includes(String(page));
    const isFooterVisible = useIntersectionObserver(showFooter ? footerSection : null);

    const useOrbeeInHomePage = thirdParty.useOrbeeAuto && (thirdParty.showOrbeeInHome || thirdParty.showOrbeeInSRP || thirdParty.showOrbeeInVDP);
    const orbeeScript = thirdParty.scriptOrbeeAuto ?? '';

    const validPages = !['login', 'admin'].includes(String(page));

    const contextValue = useMemo(() => ({
        settings: settings,
        device: settings?.device,
        openFilterInMobile: openFilterInMobile,
        openFilters,
    }), [openFilters, settings]);

    return (
        <AppContext.Provider value={contextValue}>
            <Head>
                <title>{titlePage}</title>
                {keywords &&
                    <meta name="keywords" content={keywords} />
                }
                <meta name="description" content={metaDescription || `${companyInfo.companyName} - used car dealer`} />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                {!(companyInfo.companyCode === 1 && websiteId === 6) && theme.fontLink?.split('<link').filter((c: string) => c !== '').map((item: any) => {
                    const currentRel = item?.match(/rel="([^"]*)/)[1];
                    const currentHref = item?.match(/href="([^"]*)/)[1];
                    const currentAs = item?.match(/as="([^"]*)/)?.[1];
                    const currentCrossOrigin = item?.includes("crossorigin");
                    if (currentCrossOrigin) return <link key={currentHref} rel={currentRel} href={currentHref} as={currentAs} crossOrigin="" />;
                    return <link key={currentHref} rel={currentRel} href={currentHref} as={currentAs} />;
                })}
                {companyInfo.companyCode === 1 && websiteId === 6
                    &&
                    <>  
                        <link rel="preconnect" href="https://fonts.googleapis.com" />
                        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
                        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
                    </>
                }
                <link
                    href={cloudinaryLoader({ src: iconImage, width: 32 })}
                    rel="icon"
                    type="image/png"
                />

                <link rel="preload" href="/static/styles/font-awesome.min.css" as="style" />
                <link rel="preload" href="/static/styles/flaticon.css" as="style" />
                <link rel="preload" href="/static/styles/bootstrap.min.css" as="style" />
                <link rel="preload" href="/static/styles/mega_menu.min.css" as="style" />
                <link rel="preload" href="/static/styles/layout.min.css" as="style" />
                <link rel="preload" href="/static/styles/layout-custom.min.css" as="style" />
                <link rel="preload" href="/static/styles/responsive.min.css" as="style" />
                <link rel="preload" href="/static/styles/style-customizer.min.css" as="style" />
                <link rel="preload" href="/static/styles/ada.min.css" as="style" />
                
                <link media="all" rel="stylesheet" href="/static/styles/font-awesome.min.css" />
                <link media="all" rel="stylesheet" href="/static/styles/flaticon.css" />
                <link media="all" rel="stylesheet" href="/static/styles/bootstrap.min.css" />
                <link media="all" rel="stylesheet" href="/static/styles/mega_menu.min.css" />
                <link media="all" rel="stylesheet" href="/static/styles/layout.min.css" />
                <link media="all" rel="stylesheet" href="/static/styles/layout-custom.min.css" />
                <link media="all" rel="stylesheet" href="/static/styles/responsive.min.css" />
                <link media="all" rel="stylesheet" href="/static/styles/style-customizer.min.css" />
                <link media="all" rel="stylesheet" href="/static/styles/ada.min.css" />

                {homeSkin && (
                    <>
                        <link rel="preload" href={`/static/styles/skins/${homeSkin}.css`} as="style" />
                        <link media="all" rel="stylesheet" href={`/static/styles/skins/${homeSkin}.css`} />
                    </>
                )}

                {['Financing', 'Service', 'Service Schedule'].includes(String(page)) && (
                    <>
                        <link rel="preload" href={`/static/styles/${page}.module.css`} as="style" />
                        <link media="all" rel="stylesheet" href={`/static/styles/${page}.module.css`} />
                    </>
                )}
            </Head>
            {theme.fontCssRule && fontRuleCSS && (
                <style jsx>{`${stringMinify(fontRuleCSS)}`}</style>
            )}
            {globalStyles && (
                <style jsx>{`${stringMinify(globalStyles)}`}</style>
            )}
            {!['srp'].includes(String(page)) && (
                <style>{'.menu-filter-vehicles{display:none}'}</style>
            )}
            {useOrbeeInHomePage && orbeeScript && validPages
                && <RenderScriptsContent scriptContent={orbeeScript} scriptPosition="scriptOnHead" />
            }
            <Header
                theme={theme}
                navBar={navBar}
                thirdParty={thirdParty}
                home={home}
            />
            <main id="main">{props.children}</main>
            {showFooter &&
                <div className="div-observer" ref={footerSection}>
                    {isFooterVisible && (
                        <Suspense fallback={<Loading />}>
                            <Footer
                                footer={footer}
                                companyInformation={companyInfo}
                                generalInfo={generalInfo}
                            />
                        </Suspense>
                    )}
                </div>
            }

            <div className={`scroll-top ${scrollTopType === "arrow" ? " arrow-top" : " car-top"}`}
                onClick={scrollToTop}
            >
                <span>
                    <span className="ax-top">Top</span>
                    <Image
                        src={`${scrollTopType == "arrow"
                            ? "/static/images/arrow-up.png"
                            : "/static/images/car.png"
                        }`}
                        alt="go to the top"
                        width={scrollTopType == "arrow" ? 25 : 74}
                        height={scrollTopType == "arrow" ? 25 : 114}
                        loading="lazy"
                    />
                </span>
            </div>
        </AppContext.Provider>
    );
}

export default Layout;
