import { useEffect, useState } from "react";

const useIntersectionObserver = (reference: any) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleIntersect = (entries:any, _observer:any) => {
            if (entries[0].isIntersecting) {
                setIsVisible(true);
                _observer.unobserve(entries[0].target);
                _observer.disconnect();
            }
        };

        const observer = new IntersectionObserver(handleIntersect);

        if (reference) {
            observer.observe(reference.current);
        }

        // If unmounting, then disconnect
        return () => observer.disconnect();
    }, [reference]);

    return isVisible;
};

export default useIntersectionObserver;
