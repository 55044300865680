import { ApolloClient, InMemoryCache, NormalizedCacheObject, split, ApolloProvider } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';

const GRAPHQL_API = process.env.GRAPHQL_API;
const GRAPHQL_WS_URL = String(process.env.GRAPHQL_WS_URL);
const GRAPHQL_API_TOKEN = process.env.GRAPHQL_API_TOKEN;

let currentToken = '';
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const getToken = () => currentToken || '';

// change to createUploadLink from apollo-upload-client when the upload is needed
const httpLink = createUploadLink({
    uri: GRAPHQL_API,
    credentials: 'same-origin',
});

const wsLink = typeof window !== 'undefined' ? new WebSocketLink({
    uri: GRAPHQL_WS_URL,
    options: {
        reconnect: true,
        lazy: true,
        connectionParams: async () => {
            return {
                authorization: getToken(),
                "x-auth-type": "oauth2",
                "content-type": "application/json"
            };
        },
    },
}) : null;

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: getToken(),
            "x-auth-type": "oauth2",
            'Access-Control-Allow-Origin': '*',
        },
    };
});

const errorLink = onError(({ networkError, graphQLErrors, operation }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${operation?.operationName}, variables: ${operation?.variables && JSON.stringify(operation?.variables)}`
            )
        );
    if (networkError)
        console.error(
            `[Network error]: ${networkError}. Backend is unreachable. Is it running?`
        );
});

const link = typeof window !== 'undefined' && wsLink !== null ? split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    errorLink.concat(authLink.concat(httpLink)),
) : errorLink.concat(authLink.concat(httpLink));

const createApolloClient = (token: string|undefined) => {
    if (token) currentToken = token;
    return new ApolloClient({
        ssrMode: typeof window === 'undefined', // SSR only for Node.js
        link,
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });
};

// use this only for admin section
const useApollo = (token = undefined) => {
    const _apolloClient = apolloClient ?? createApolloClient(token);

    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient && !token) apolloClient = _apolloClient;

    return _apolloClient;
};

// use this for the site request
const client = createApolloClient(GRAPHQL_API_TOKEN);

export {
    client,
    useApollo,
    ApolloProvider,
};