import { gql } from "@apollo/client";

export const GENERIC_QUERY = gql`
  query getWebsiteContent(
    $keyContentIds: [String!]!
    $websiteId: Int
    $templateId: String,
    $identifier: String
  ) {
    getWebsiteContent(
      keyContentIds: $keyContentIds
      websiteId: $websiteId
      templateId: $templateId
      identifier: $identifier
    ) {
      key
      value
    }
  }
`;

export const CLEAR_CACHE = gql`
  subscription websiteChanged {
    websiteChanged {
      value
    }
  }
`;
